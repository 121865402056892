
import React from 'react';


import { CRow } from '@coreui/react';
 const AStatusIndicator = ({ status }) => {
    const AStatusDot = ({ status, name }) => {
        return name === true ? (
            <div
                style={{
                    height: '20px',
                    width: '20px',
                    borderRadius: '20px',
                    backgroundColor: 'red',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            />
        ) : (
            <CRow
                style={{
                    height: '20px',
                    width: '20px',
                    borderRadius: '20px',
                    backgroundColor: status ? '#193739' : 'grey',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                {status && (
                    <div
                        style={{
                            height: '10px',
                            width: '10px',
                            borderRadius: '10px',
                            backgroundColor: '#40B097',
                        }}
                    />
                )}
            </CRow>
        );
    }; 
    return (
        <>
            <div
                style={{
                    marginVertical: '15px',
                    width: '80%',
                    alignSelf: 'center',
                }}>
                <CRow>
                    <div
                        style={{
                            borderRadius: '5px',
                            width: '50%',
                            borderStyle: 'dashed',
                            borderWidth: '1px',
                            borderColor:
                                status == 'contracted' ||
                                    status == 'completed' ||
                                    status === 'cancled'
                                    ? '#40B097'
                                : 'grey',
                        }}
                    />          <div
                        style={{
                            borderRadius: '1px',
                            width: '50%',
                            borderStyle: 'dashed',
                            borderWidth: '1px',
                            borderColor:
                                status == 'completed' || status === 'cancled'
                                    ? '#40B097'
                                : 'grey',
                        }}
                    />
                </CRow>       
                 <CRow
                    style={{
                        justifyContent: 'space-between',
                        marginTop: '-10px',
                    }}>
                    <AStatusDot
                        status={
                            status === 'published' ||
                            status === 'contracted' ||
                            status === 'completed' ||
                            (status === 'cancled' && true)
                        }
                    />
                    <AStatusDot
                        status={
                            status === 'completed' ||
                            status === 'contracted' ||
                            (status === 'cancled' && true)
                        }
                    />
                    <AStatusDot
                        name={status === 'cancled' && true}
                        status={status === 'completed' && true}
                    />
                </CRow>
            </div>
            <CRow
                style={{
                    width: '90%',
                    alignSelf: 'center',
                    marginTop: '10px',
                }}>
                <div style={{ width: '33.33%' }}>
                    <p
                        style={{
                            fontWeight:'bold',
                              color:
                                status === 'published' ||
                                    status === 'contracted' ||
                                    status === 'completed' ||
                                    status === 'cancled'
                                    ? '#40B097'
                                    : 'grey',
                        }}>
                        Published
                    </p>
                </div>       
                 <div style={{ width: '33.33%' }}>
                    <p
                        style={{
                            fontWeight:'bold',
                            textAlign: 'center',
                            color:
                                status === 'completed' ||
                                    status === 'contracted' ||
                                    status === 'cancled'
                                    ? '#40B097'
                                    : 'grey',
                        }}>
                        Contracted
                    </p>
                </div>       
                 <div style={{ width: '33.33%' }}>
                    
                    <p
                        style={{
                         fontWeight:'bold',
                            textAlign: 'right',
                            color:
                                status === 'completed' ?   '#40B097'
                                : 'grey',
                        }}>
                        {status!== 'cancled'?"Completed":"Cancelled"}
                    </p>
                </div>
            </CRow>      
            <CRow
                style={{
                    width: '60%',
                    alignSelf: 'center',
                    marginTop: '-60px',
                }}>
                <div style={{ width: '33.33%' }}>
                    <p
                        style={{
                         
                            color: 'grey',
                            textAlign: 'center',
                        }}>
                        {/* 4 Hours */}
                    </p>
                </div>       
                 <div style={{ width: '33.33%' }}></div>        
                 <div style={{ width: '33.33%' }}>
                    <p
                        style={{
                          
                            color: 'grey',
                            textAlign: 'center',
                        }}>
                        {/* 4 Hours */}
                    </p>
                </div>
            </CRow>
        </>
    );
}; 
export default AStatusIndicator; 